<script lang="ts" setup>
import { useWallet } from '@solana/wallet-adapter-vue'
import { LAMPORTS_PER_SOL } from '@solana/web3.js'
import jsolIcon from '~/assets/img/tokens/jsol.svg'
import { MIN_REMAINING_BALANCE, STRATEGY_STAKE_PREFIX } from '~/config'
import { formatPct, formatPrice } from '~/utils'

const {
  onlyStake = false,
  strategyId,
  stakeId,
  withdrawAvailableAmount,
} = defineProps<{
  onlyStake?: boolean
  strategyId?: number
  stakeId?: number
  withdrawAvailableAmount?: number | string
}>()

const { t } = useTranslation()

const tabs = computed<string[]>(() => [t('stakeBox.tabs.stake'), t('stakeBox.tabs.unstake')])
const activeTab = ref<string>(String(tabs.value[0]))

const unstakeTabs = ['instant', 'delayed']
const unstakeActiveTab = ref<string>(String(unstakeTabs[0]))

const { publicKey } = useWallet()

const depositStore = useDepositStore()
const { withdrawing, setAmount, withdraw } = useWithdraw()

const balanceStore = useBalanceStore()
const solBalance = computed(() => balanceStore.solBalance || 0)
const jsolBalance = computed(() => withdrawAvailableAmount || balanceStore.tokenBalance || 0)

const coinRateStore = useCoinRateStore()
const solPrice = computed(() => coinRateStore.solana?.price)
const jsolPrice = computed(() => coinRateStore.jpool?.price)

const apyStore = useApyStore()
const apy = computed(() => {
  const apyLiquid = apyStore.apy.staking + apyStore.apy.jito
  return formatPct.format(apyLiquid)
})

const stakePoolStore = useStakePoolStore()
const exchangeRate = computed(() => stakePoolStore.exchangeRate)
const fees = computed(() => stakePoolStore.fees)

const stakeAmount = ref<string | number>()
const receiveJsolAmount = ref<string | number>()

const withdrawAmount = ref<string | number>()
const receiveSolAmount = ref<string | number>()

const directStakeStore = useDirectStakeStore()
const hyApy = computed(() => strategyId && directStakeStore.calcEstApy(stakeAmount.value ? Number(stakeAmount.value) * LAMPORTS_PER_SOL : 0))
const totalApy = computed(() => strategyId && Number(apyStore.apy.staking + apyStore.apy.jito) * 100 + Number(hyApy.value))

const hyApiTooltipLabel = computed(() => {
  if (!strategyId) {
    return
  }
  return `
  ${hyApy.value?.toFixed(2)}% HY + ${apy.value} ${t('other.stakingAPY')} <br><br>
  ${t('highYield.info.hyCurrentApyDesc')}
  `
})

const loading = computed(() => depositStore.loading)

const cardTitle = computed(() => activeTab.value === tabs.value[0] ? `${tabs.value[0]} SOL` : `${tabs.value[1]} SOL`)

const epochStore = useEpochStore()
const epochTimeRemaining = computed(() => epochStore.epochTimeRemaining)

const time = computed(() => {
  const _h = epochTimeRemaining.value / 1000 / 60 / 60
  const h = Math.floor(_h)
  const m = Math.floor((_h - h) * 60)
  const s = Math.ceil(((_h - h) * 60 - m) * 60)
  return { h, m: m < 10 ? `0${m}` : m, s: s < 10 ? `0${s}` : s }
})

watch(stakeAmount, (amount) => {
  const sol = Number(amount) || 0
  if (sol <= 0) {
    receiveJsolAmount.value = undefined
    return
  }

  let value = sol * exchangeRate.value
  value -= value * fees.value.solDepositFee
  receiveJsolAmount.value = formatPrice(Math.max(value, 0), 5, 5)
})

watch([withdrawAmount, unstakeActiveTab], ([amount, tab]) => {
  const jsol = Number(amount) || 0
  if (jsol <= 0) {
    receiveSolAmount.value = undefined
    return
  }
  let value = jsol * (1 / exchangeRate.value)
  const withdrawalFeeType = tab === unstakeTabs[0] ? 'solWithdrawalFee' : 'withdrawalFee'
  value -= value * fees.value[withdrawalFeeType]
  receiveSolAmount.value = formatPrice(Math.max(value, 0), 5, 5)

  setAmount(Number(amount))
})

watch(publicKey, (pubkey) => {
  if (!pubkey) {
    stakeAmount.value = undefined
    receiveJsolAmount.value = undefined
    withdrawAmount.value = undefined
    receiveSolAmount.value = undefined
    activeTab.value = String(tabs.value[0])
  }
})

watch(tabs, (_next, prev) => {
  const activeTabIdx = prev.indexOf(activeTab.value)
  activeTab.value = String(tabs.value[Math.max(activeTabIdx, 0)])
})

async function stakeHandler() {
  if (!publicKey.value) {
    const connectBtn = document.querySelector('.connect-wallet') as HTMLElement
    connectBtn?.click()
    return
  }
  if (!stakeAmount.value || Number(stakeAmount.value) <= 0) {
    const inputEl = document.querySelector('.input-widget-stake')
    const inputField = inputEl?.querySelector('input')
    inputField?.focus()
    return
  }

  if (Number.isNaN(Number(stakeAmount.value))) {
    return
  }

  await depositStore.depositSol(Number(stakeAmount.value), strategyId ? `${STRATEGY_STAKE_PREFIX}${strategyId}` : undefined)

  Analytics.track({
    event: strategyId ? 'stake_highyield' : 'stake',
    address: publicKey.value?.toBase58(),
    amount: stakeAmount.value,
  },
  )

  stakeAmount.value = undefined
  receiveJsolAmount.value = undefined
}

// function feeNormalized(fee: number) {
//   return `${(fee * 100).toFixed(2)}`
// }

async function unstakeHandler() {
  if (!withdrawAmount.value || Number(withdrawAmount.value) <= 0) {
    const inputEl = document.querySelector('.input-widget-unstake')
    const inputField = inputEl?.querySelector('input')
    inputField?.focus()
    return
  }

  if (Number.isNaN(Number(withdrawAmount.value))) {
    return
  }

  const forceDelayed = unstakeActiveTab.value === 'delayed'
  await withdraw(forceDelayed, stakeId)

  Analytics.track({
    event: 'unstake',
    address: publicKey.value?.toBase58(),
    amount: withdrawAmount.value,
    unstake_type: unstakeActiveTab.value,
  })

  withdrawAmount.value = undefined
  receiveSolAmount.value = undefined
}
</script>

<template>
  <div class="stake-card card">
    <div class="stake-card__title">
      {{ cardTitle }}
    </div>

    <j-btn-group v-if="publicKey && !onlyStake" v-model="activeTab" :buttons="tabs" />

    <div v-if="activeTab === tabs[0]" class="inputs-wrapper inputs-wrapper--stake">
      <input-widget
        v-model="stakeAmount"
        :label-left="$t('stakeBox.detailInfo.solToStake')"
        class="input-widget-stake"
        :balance="solBalance"
        :price="solPrice"
        :fee="MIN_REMAINING_BALANCE"
        :label-right="`${$t('common.balance')}: ${solBalance} SOL`" :rules="[
          (val) => val && Number(solBalance) >= Number(val) || $t('stakeBox.warningBalanceStake'),
        ]"
      />

      <input-widget
        v-model="receiveJsolAmount" class="input-widget-receive" :balance="Number(receiveJsolAmount)"
        :price="jsolPrice" :label-left="$t('stakeBox.detailInfo.youReceive')" :icon="jsolIcon" readonly format
      >
        <template #label-right>
          <div v-if="strategyId" class="apy">
            {{ totalApy?.toFixed(2) }}% APY <info-tooltip :text="String(hyApiTooltipLabel)" />
          </div>
          <div v-else class="apy">
            {{ apy }} APY
          </div>
        </template>
      </input-widget>
    </div>

    <div v-if="activeTab === tabs[1] && !onlyStake" class="inputs-wrapper inputs-wrapper--unstake">
      <input-widget
        v-model="withdrawAmount" class="input-widget-unstake" :balance="Number(jsolBalance)"
        :price="jsolPrice" :label-left="$t('stakeBox.amountToUnstake')" :label-right="`${$t('common.balance')}: ${jsolBalance} JSOL`" :icon="jsolIcon"
        :rules="[
          (val) => val && Number(jsolBalance) >= Number(val) || $t('stakeBox.warningBalanceUnstake'),
        ]"
      />

      <j-btn-group v-model="unstakeActiveTab" class="unstake-tabs" :buttons="unstakeTabs">
        <template #default="{ label, isActive, select }">
          <j-btn :variant="isActive ? 'primary' : 'secondary'" @click="select">
            <div v-if="label === 'instant'" class="unstake-btn-label">
              <span class="title">
                {{ $t('stakeBox.action.unstakeNow') }}
              </span>
              <span class="desc">
                <!-- {{ $t('stakeBox.detailInfo.feeInstant', { fee: feeNormalized(fees.solWithdrawalFee) || 0 }) }} -->
              </span>
            </div>
            <div v-if="label === 'delayed'" class="unstake-btn-label">
              <span class="title">
                {{ $t('stakeBox.action.unstakeDelayed') }}
              </span>

              <span class="desc">
                <!-- {{ $t('stakeBox.detailInfo.feeDelayed', { fee: feeNormalized(fees.withdrawalFee) || 0 }) }} -->
                <span class="timer">
                  {{ time.h }}{{ $t('epoch.time.hour') }} {{ time.m }}{{ $t('epoch.time.minute') }} {{ time.s }}{{
                    $t('epoch.time.second') }}
                </span>
              </span>
            </div>
          </j-btn>
        </template>
      </j-btn-group>

      <input-widget
        v-model="receiveSolAmount"
        :label-left="$t('stakeBox.detailInfo.youReceive')"
        class="input-widget-receive"
        :balance="Number(receiveSolAmount)"
        :price="solPrice" readonly
        format
      >
        <template #label-right>
          <div class="apy">
            {{ apy }} APY
          </div>
        </template>
      </input-widget>
    </div>

    <j-btn
      v-if="activeTab === tabs[0]" :loading="loading" class="stake-card__btn" variant="primary" size="lg" pill
      @click="stakeHandler"
    >
      {{ cardTitle }}
    </j-btn>

    <j-btn
      v-else :loading="withdrawing" class="stake-card__btn" variant="primary" size="lg" pill
      @click="unstakeHandler"
    >
      {{ cardTitle }}
    </j-btn>
  </div>
</template>

<script lang='ts' setup>
</script>

<template>
  <section class="faq">
    <h1 class="container-title text-48">
      FAQ
    </h1>

    <j-accordion :title="$t('faq.staking.title')">
      <p>
        {{ $t('faq.staking.descr') }}
      </p>
      <p v-html="$t('faq.staking.readMore')" />
    </j-accordion>

    <j-accordion :title="$t('faq.stake.title')">
      <p>
        {{ $t('faq.stake.descr') }}
      </p>
    </j-accordion>

    <j-accordion :title="$t('faq.rewards.title')">
      <p>
        {{ $t('faq.rewards.descr') }}
      </p>
      <p v-html="$t('faq.rewards.readMore')" />
    </j-accordion>

    <j-accordion :title="$t('faq.delegator.title')">
      <p v-html="$t('faq.delegator.descr')" />
    </j-accordion>
  </section>
</template>

<style lang="scss">
section.faq {
  @media (max-width: $breakpoint-xs) {
    .container-title {
      font-size: 32px;
    }

    .accordion-button {
      font-size: 20px;
      padding: $spacing-24 0;

      svg {
        width: 20px;
        height: 20px;
      }
    }
  }
  .j-accordion {
    .accordion-body {
      p {
        margin: 12px 0;

        &:nth-child(1) {
          margin-top: 0;
        }
      }
    }
  }
}
</style>
